// Import stylů
import '../assets/css/web.css'

// Import Preline
import 'preline/dist/preline.js'

import '../assets/images/cover-topparking.jpg'
import '../assets/images/topparking.png'

// Import všech assetů
import.meta.glob([
  '../assets/images/**/*.{jpg,jpeg,png,gif,svg}', // Specifikace obrazových formátů
  '../assets/fonts/**/*.{woff,woff2,eot,ttf,otf}', // Pokud používáte fonty
  '../assets/**/*.ico',
])

// Inicializace Preline (pokud je potřeba)
document.addEventListener('DOMContentLoaded', () => {
  // Zde můžete přidat inicializaci Preline nebo další kód
})
